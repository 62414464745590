<template>
	<div :class="themeClass" class="width-fill bg-F8F9FE">
		<div class="min-width1100 max-width1100 margin-lr-auto box-sizing">
			<!-- 开店表单 -->
			<div class="underReviewBg-img flex-row-center-center">
				<div class="underReview-box bg-fff radius10 flex-row-start-center padding-20">
					<div class="flex-row-start-center">
						<div v-if="type==1" class="underReview1-img"></div>
						<div v-else class="underReview1-img1"></div>
						<div class="flex-colum"> 
							<div class="flex-colum color-theme font-size20 font-weight700">
								
									<div class="margin-b-15" >议价单已生成,请联系承运商:{{dataobj.fCarrierName}},联系电话:{{dataobj.fCarrierPhone}}。</div>
							
								
							</div>
							<router-link to="/">
								<div class="underReview-btn text-center border-color-theme color-theme">
									返回首页
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from 'vuex';
	export default {
		data() {
			return {
				type: null,//是否成功
				dataobj:{
					fCarrierName:'',
					fCarrierPhone:'',
				},//议价单的相关数据
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			...mapActions(["setUser"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			let  acquiredata = JSON.parse(this.$route.params.fname)
			console.log(acquiredata)
			this.dataobj = acquiredata[0]
		},
		methods: {
		}
	}
</script>

<style scoped lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.underReview-box {
		width: 860px;
		height: 213px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	}

	.underReviewBg-img {
		width: 100%;
		height: 550px;
		// background-image: url(../../assets/imgs/HomePage/OpenShopUnderReviewBg.png);
		background-size: 100% 100%;
	}

	.underReview1-img {
		width: 180px;
		height: 180px;
		background-image: url(../../assets/imgs/HomePage/SHZ.png);
		background-size: 100% 100%;
		margin-right: 50px;
		margin-left: 50px;
	}

	.underReview1-img1 {
		width: 180px;
		height: 180px;
		background-image: url(../../assets/imgs/HomePage/RZCG.png);
		background-size: 100% 100%;
		margin-right: 50px;
		margin-left: 50px;
	}

	.underReview-btn {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}
</style>
